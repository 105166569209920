import { Dropdown, Select, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MaterialIcon from "material-icons-react";
import {
  convertToPercentage,
  dateForHumans,
  formatImgPath,
  timeAmPm,
  transformToPastTense,
} from "../../../../utils";
import "./candidates.css";
import {
  fetchJobApplicationsApplicant,
  saveJobApplication,
} from "../../../../features/applicantJob/applicantJobSlice";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import ApplicantDrawer from "../../../applicants/applicant-tabs/ApplicantDrawer";
import toast from "react-hot-toast";
import {
  cleanUpapplicantSingleCreateApplicant,
  fetchApplicantsById,
  setApplicantEditId,
  setEditApplicantDetails,
  setEditDisability,
  setEditEducation,
  setEditJobSeekerInformation,
  setEditSkills,
  setTabsActiveKey,
  setViewApplicantDetails,
  setViewDisability,
  setViewEducation,
  setViewJobSeekerInformation,
  setViewSkills,
} from "../../../../features/applicant/applicantSlice";
import {
  setEditContact,
  setViewContact,
} from "../../../../features/client/clientSlice";
import { setRefetch, setRefetchKey } from "../../../../features/cv/cvSlice";
import InterviewScoreModal from "./modal/InterviewScoreModal";
import moment from "moment";
import OfferModal from "./modal/OfferModal";
import { fetchRecruitmentProcessMenus } from "../../../../features/settings/settingSlice";
import { fetchJobStatsCount } from "../../../../features/jobs/jobSlice";
import {
  cleanCandidate,
  fetchSingleJap,
} from "../../../../features/candidate/candidateSlice";
import carretDown from "../../../../assets/caretDownBlue.svg";

function Candidates({
  intervalTime,
  rowSelected,
  setRowSelected,
  refresh,
  activityJobId,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { refetch, singleCreateJob, jobEditId } = useSelector(
    (state) => state.jobs
  );
  const { refetchKey } = useSelector((state) => state.cv);
  const { user } = useSelector((state) => state.auth);
  const { agencyObj } = useSelector((state) => state.agency);
  const { recruitmentData, recruitmentProcess } = useSelector(
    (state) => state.settings
  );

  const [open, setOpen] = useState(false);
  const [cvType, setCvType] = useState();
  const [prodd, setProdd] = useState("");
  const [applicantEdit, setApplicantEdit] = useState();
  const [loading, setLoading] = useState(false);

  const showApplicantDrawer = async (id) => {
    await setLoading(true);
    await dispatch(setViewApplicantDetails(true));
    await dispatch(setEditApplicantDetails(false));
    await dispatch(setViewJobSeekerInformation(true));
    await dispatch(setEditJobSeekerInformation(false));
    await dispatch(setViewContact(true));
    await dispatch(setEditContact(false));
    await dispatch(setViewSkills(true));
    await dispatch(setEditSkills(false));
    await dispatch(setViewEducation(true));
    await dispatch(setEditEducation(false));
    await dispatch(setViewDisability(true));
    await dispatch(setEditDisability(false));
    await dispatch(cleanUpapplicantSingleCreateApplicant());
    await dispatch(setApplicantEditId(id));
    await dispatch(setRefetch());
    await dispatch(setRefetchKey("1"));
    await dispatch(setTabsActiveKey("1"));
    await setLoading(false);
    if (!loading) {
      await setOpen(true);
    }
  };
  async function fetchSingleJapDetails(id) {
    await dispatch(
      fetchSingleJap({
        japId: id,
      })
    );
  }

  const [isModalOpenConfirmation, setIsModalOpenConfirmation] = useState(false);
  const showModalConfirmation = async (item) => {
    setProdd(item);
    setIsModalOpenConfirmation(true);
  };

  const [isModalOpenScore, setIsModalOpenScore] = useState(false);
  const showModalScore = async (item) => {
    setProdd(item);
    setIsModalOpenScore(true);
  };

  const [isModalOpenOffer, setIsModalOpenOffer] = useState(false);
  const showModalOffer = async (item) => {
    setProdd(item);
    setIsModalOpenOffer(true);
  };

  const [selectedRowKeysBulk, setSelectedRowKeysBulk] = useState([]);

  const [rowIdBulk, setRowIdBulk] = useState([]);

  const handleSubmit = async () => {
    if (prodd === "SCHEDULE INTERVIEW") {
      navigate("/activity-list");
      return;
    }
    if (rowIdBulk?.length > 1) {
      const results = await Promise.all(
        rowIdBulk?.map((item) =>
          dispatch(
            saveJobApplication({
              japId: item?.japId,
              japProgressStatus: prodd,
              jobUpdatedBy: user?.usrId,
              japStatus:
                prodd == "ATTESTATION DOCUMENTS" ? "MIGRANT_WORKER" : "",
            })
          )
        )
      );
      const allSuccess = results.every((res) => res?.payload?.success);

      if (allSuccess) {
        toast.success(results[0]?.payload?.messages?.message);
        fetchCandidateJobApplications();
        fetchCount();
        setIsModalOpenConfirmation(false);
      } else {
        toast.error(
          results.find((res) => !res?.payload?.success)?.payload?.messages
            ?.message
        );
      }
      return;
    }
    const res = await dispatch(
      saveJobApplication({
        japId: applicantEdit?.japId,
        japProgressStatus: prodd,
        jobUpdatedBy: user?.usrId,
        japStatus: prodd == "ATTESTATION DOCUMENTS" ? "MIGRANT_WORKER" : "",
      })
    );
    const notObj = {
      ntfnInstId: user?.usrInstId,
      ntfnSenderId: user?.usrId,
      ntfnReceiverId: applicantEdit?.japUsrId,
      ntfnBody: `You have been shortlisted by ${agencyObj?.instName}`,
    };
    if (res?.payload?.success) {
      toast.success(res?.payload?.messages?.message);
      // if(prodd === "SHORTLIST"){
      //   await dispatch(saveNotification(notObj))
      // }
      fetchCandidateJobApplications();
      fetchCount();
      setIsModalOpenConfirmation(false);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const contentBulk = `Are you sure you want to ${prodd} ${rowIdBulk?.length} candidates ?`;
  const content = `Are you sure you want to ${prodd} candidate ${applicantEdit?.japFullName}?`;
  const [formData, setFormData] = useState(activityJobId ? "Shortlist" : "All");

  const [receivedId, setReceivedId] = useState(
    jobEditId ? jobEditId : singleCreateJob?.jobId
  );

  const { jobApplicationPerJob, jobApplicationLoading } = useSelector(
    (state) => state.applicantJob
  );

  const [applicantJobApplicationList, setApplicantJobApplicationList] =
    useState(jobApplicationPerJob);

  const hasApplicationDateData = applicantJobApplicationList?.some(
    (item) =>
      (item?.japProgressStatus !== null &&
        item?.japProgressStatus !== undefined &&
        formData === "Active") ||
      formData === "All"
  );
  const hasApplicationTimeData = applicantJobApplicationList?.some(
    (item) =>
      (item?.japProgressStatus !== null &&
        item?.japProgressStatus !== undefined &&
        formData === "Active") ||
      formData === "All"
  );

  const hasMatchData = applicantJobApplicationList?.some(
    (item) =>
      item?.japProgressStatus !== null &&
      item?.japProgressStatus !== undefined &&
      item?.japProgressStatus != "INTERVIEW RESULTS" &&
      item?.japProgressStatus != "PRE DEPARTURE TRAINING" &&
      item?.japProgressStatus != "ATTESTATION"
  );
  const hasStageData = applicantJobApplicationList?.some(
    (item) =>
      item?.japProgressStatus !== null && item?.japProgressStatus !== undefined
  );
  const hasShortlistedByData = applicantJobApplicationList?.some(
    (item) => item?.japProgressStatus == "SHORTLIST" && formData === "SHORTLIST"
  );
  const hasShortlistedDateData = applicantJobApplicationList?.some(
    (item) => item?.japProgressStatus == "SHORTLIST" && formData === "SHORTLIST"
  );
  const hasInterviewStartTimeData = applicantJobApplicationList?.some(
    (item) =>
      item?.japProgressStatus == "SHEDULE INTERVIEW" &&
      formData === "SHEDULE INTERVIEW"
  );
  const hasInterviewEndTimeData = applicantJobApplicationList?.some(
    (item) =>
      item?.japProgressStatus == "SHEDULE INTERVIEW" &&
      formData === "SHEDULE INTERVIEW"
  );
  const hasInterviewSessionData = applicantJobApplicationList?.some(
    (item) =>
      item?.japProgressStatus == "SHEDULE INTERVIEW" &&
      formData === "SHEDULE INTERVIEW"
  );

  const hasInterviewScoreData = applicantJobApplicationList?.some(
    (item) =>
      (item?.japProgressStatus == "INTERVIEW RESULTS" ||
        item?.japProgressStatus == "OFFER") &&
      (formData === "INTERVIEW RESULTS" || formData === "OFFER")
  );
  const hasInterviewCommentsData = applicantJobApplicationList?.some(
    (item) =>
      (item?.japProgressStatus == "INTERVIEW RESULTS" ||
        item?.japProgressStatus == "OFFER") &&
      (formData === "INTERVIEW RESULTS" || formData === "OFFER")
  );
  const hasInterviewDateData = applicantJobApplicationList?.some(
    (item) =>
      (item?.japProgressStatus == "INTERVIEW RESULTS" ||
        item?.japProgressStatus == "OFFER") &&
      (formData === "INTERVIEW RESULTS" || formData === "OFFER")
  );
  const hasOfferStartDateData = applicantJobApplicationList?.some(
    (item) => item?.japProgressStatus == "OFFER" && formData === "OFFER"
  );
  const hasCountyData = applicantJobApplicationList?.some(
    (item) =>
      item?.japProgressStatus == "ATTESTATION" && formData === "ATTESTATION"
  );
  const hasAttestationOfficerData = applicantJobApplicationList?.some(
    (item) =>
      item?.japProgressStatus == "ATTESTATION" && formData === "ATTESTATION"
  );
  const hasVenueData = applicantJobApplicationList?.some(
    (item) =>
      item?.japProgressStatus == "ATTESTATION" && formData === "ATTESTATION"
  );
  const hasLocationData = applicantJobApplicationList?.some(
    (item) =>
      item?.japProgressStatus == "PRE DEPARTURE TRAINING" &&
      formData === "PRE DEPARTURE TRAINING"
  );
  const hasTrainingDateData = applicantJobApplicationList?.some(
    (item) =>
      item?.japProgressStatus == "PRE DEPARTURE TRAINING" &&
      formData === "PRE DEPARTURE TRAINING"
  );
  const hasTrainingTimeData = applicantJobApplicationList?.some(
    (item) =>
      item?.japProgressStatus == "PRE DEPARTURE TRAINING" &&
      formData === "PRE DEPARTURE TRAINING"
  );
  const hasDeclineData = applicantJobApplicationList?.some(
    (item) => item?.japProgressStatus == "DECLINE" && formData === "DECLINE"
  );

  const columns = [
    {
      title: "Candidate Name",
      render: (item) => (
        <>
          <div className="flex justify-between">
            <div className="flex items-center justify-start">
              <img
                className="w-9 h-9 rounded-full object-cover"
                src={
                  item?.usrProfileImage
                    ? formatImgPath(item?.usrProfileImage)
                    : "https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png"
                }
                alt="Avatar"
              />
              <span className="table-name mx-3">{item?.japFullName}</span>
            </div>
            <div className={`flex items-center ${activityJobId && "hidden"}`}>
              <button
                className="p-0 mx-4 flex items-end"
                type="button"
                onClick={() => {
                  dispatch(
                    fetchApplicantsById({
                      usrId: item?.japUsrId,
                    })
                  );
                }}
              >
                <MaterialIcon
                  color="#000"
                  icon="visibility"
                  onClick={() => {
                    fetchSingleJapDetails(item?.japId);
                    showApplicantDrawer(item?.japUsrId);
                    setCvType(item?.japCvType);
                  }}
                />
              </button>
            </div>
          </div>
        </>
      ),
    },
    ...(hasMatchData
      ? [
          {
            title: "Job Match",
            render: (item) => {
              return (
                <div className="w-[81px] py-[4px] px-[12px] bg-[#02A548] text-white rounded-[3px] flex items-center justify-center">
                  {item?.japMatchingScore
                    ? convertToPercentage(item?.japMatchingScore)
                    : 0}{" "}
                  %
                </div>
              );
            },
          },
        ]
      : []),
    ...(hasInterviewScoreData
      ? [
          {
            title: "Interview Score",
            render: (item) => {
              return (
                <div className="w-[81px] py-[4px] px-[12px] bg-[#02A548] text-white rounded-[3px] flex items-center justify-center">
                  {item?.japInterviewScore}%
                </div>
              );
            },
          },
        ]
      : []),
      ...(hasStageData
        ? [
            {
              title: "Stage",
              render: (item) => {
                return (
                  <Tag
                    className="stage text-[1rem] font-medium font-dmSans leading-tight bg-[#F2F4F7]
                  rounded-[16px]"
                  >
                    <div className="gap-x-[6px] text-center text-[#344054] text-[1rem] flex items-center transform-lowercase">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <circle cx="4" cy="4" r="3" fill="#667085" />
                      </svg>
                      {item?.japProgressStatus}
                    </div>
                  </Tag>
                );
              },
            },
          ]
        : []),
    ...(hasDeclineData
      ? [
          {
            title: "Decline Reason",
            render: (item) => {
              return <div>{item?.japDesc}</div>;
            },
          },
        ]
      : []),
    ...(hasShortlistedByData
      ? [
          {
            title: "Shortlisted By",
            render: (item) => {
              return <div>{item?.japUpdatedByName}</div>;
            },
          },
        ]
      : []),
    ...(hasShortlistedDateData
      ? [
          {
            title: "Date Shortlisted",
            render: (item) => {
              return <div>{dateForHumans(item?.japUpdatedDate)}</div>;
            },
          },
        ]
      : []),

    ...(hasInterviewStartTimeData
      ? [
          {
            title: "Start Time",
            render: (item) => {
              return <div>{timeAmPm(item?.japInterviewStartTime)}</div>;
            },
          },
        ]
      : []),

    ...(hasInterviewEndTimeData
      ? [
          {
            title: "End Time",
            render: (item) => {
              return <div>{timeAmPm(item?.japInterviewEndTime)}</div>;
            },
          },
        ]
      : []),
    ...(hasInterviewSessionData
      ? [
          {
            title: "Interview Session",
            render: (item) => {
              return <div>{item?.japInterviewSession}</div>;
            },
          },
        ]
      : []),

    ...(hasInterviewCommentsData
      ? [
          {
            title: "Comments",
            render: (item) => {
              return <div>{item?.japInterviewRemarks}</div>;
            },
          },
        ]
      : []),

    ...(hasInterviewDateData
      ? [
          {
            title: "Interview Date",
            render: (item) => {
              return <div>{dateForHumans(item?.japJaaStartDate)}</div>;
            },
          },
        ]
      : []),
    ...(hasOfferStartDateData
      ? [
          {
            title: "Contact Start Date",
            render: (item) => {
              return <div>{dateForHumans(item?.japValidityStartDate)}</div>;
            },
          },
        ]
      : []),

    ...(hasCountyData
      ? [
          {
            title: "County",
            render: (item) => {
              return <div>{item?.dmlCreatedDate}</div>;
            },
          },
        ]
      : []),

    ...(hasAttestationOfficerData
      ? [
          {
            title: "Attestation Officer",
            render: (item) => {
              return <div>{item?.dmlCreatedDate}</div>;
            },
          },
        ]
      : []),

    ...(hasVenueData
      ? [
          {
            title: "Venue",
            render: (item) => {
              return <div>{item?.dmlCreatedDate}</div>;
            },
          },
        ]
      : []),

    ...(hasApplicationDateData
      ? [
          {
            title: "Application Date",
            render: (item) => {
              return <div>{dateForHumans(item?.japCreatedDate)}</div>;
            },
          },
        ]
      : []),

    ...(hasApplicationTimeData
      ? [
          {
            title: "Application Time",
            render: (item) => {
              return (
                <div>
                  {moment.utc(item?.japCreatedTime).format("hh : mm A")}
                </div>
              );
            },
          },
        ]
      : []),

    ...(hasLocationData
      ? [
          {
            title: "Training Location",
            render: (item) => {
              return <div>{item?.japCreatedTime}</div>;
            },
          },
        ]
      : []),
    ...(hasTrainingDateData
      ? [
          {
            title: "Training Date",
            render: (item) => {
              return <div>{dateForHumans(item?.japCreatedTime)}</div>;
            },
          },
        ]
      : []),
    ...(hasTrainingTimeData
      ? [
          {
            title: "Training Time",
            render: (item) => {
              return <div>{timeAmPm(item?.japCreatedTime)}</div>;
            },
          },
        ]
      : []),

    {
      title: "Action",
      render: (item) => (
        <>
          {rowIdBulk?.length <= 1 ? (
            <>
              {item?.japProgressStatus === "DECLINE" ? (
                <div className="cursor-not-allowed text-[#147CBC]">
                  Declined
                </div>
              ) : (
                <>
                  {" "}
                  <Dropdown
                    key={item.japId}
                    overlayStyle={{
                      width: "300px",
                      maxHeight: "400px",
                      overflowY: "auto",
                    }}
                    trigger={"click"}
                    menu={{ items: settingItems }}
                    placement="bottom"
                  >
                    <button
                      key={item.japId}
                      onClick={() => setApplicantEdit(item)}
                      className={`${
                        rowIdBulk?.length > 1
                          ? "cursor-not-allowed opacity-50"
                          : "cursor-pointer"
                      } text-[#147CBC] `}
                    >
                      <span>Choose Operation</span>
                    </button>
                  </Dropdown>
                </>
              )}
            </>
          ) : (
            <button
              className={`${
                rowIdBulk?.length > 1
                  ? "cursor-not-allowed opacity-50"
                  : "cursor-pointer"
              } text-[#147CBC] `}
            >
              <span>Choose Operation</span>
            </button>
          )}
        </>
      ),
      hidden: activityJobId,
    },
  ];

  const tableColumns = columns?.map((item) => ({
    ...item,
    hidden: item?.title === "Action" && activityJobId,
  }));

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSearch = (value) => {};

  const desiredOrder = [
    "Shortlist",
    "Schedule Interview",
    "Interview Results",
    "Offer",
    "Hire",
    "Arrival Status",
  ];
  const sortedRecruitmentProcess = [...recruitmentProcess]?.sort((a, b) => {
    return (
      desiredOrder.indexOf(a?.stageName) - desiredOrder.indexOf(b?.stageName)
    );
  });
  const normalize = (str) => str?.toLowerCase();
  const normalizedCurrentStep = normalize(
    applicantEdit?.japProgressStatus || ""
  );
  const currentStepIndex = desiredOrder.findIndex(
    (item) => normalize(item) === normalizedCurrentStep
  );

  const requiredStages = ["Shortlist", "Hire", "Arrival Status"];

  // Determine which stages to include based on rowIdBulk length
  const stagesToInclude =
    rowIdBulk?.length > 1
      ? sortedRecruitmentProcess?.filter((item) =>
          requiredStages?.includes(item?.stageName)
        )
      : sortedRecruitmentProcess;

  const settingItems = [
    ...(stagesToInclude?.map((item) => {
      const isDisabled =
        desiredOrder?.indexOf(item?.stageName) < currentStepIndex;

      return {
        label: (
          <div
            className={`font-dmSans text-black font-[400] text-[19px] ${
              isDisabled ? "cursor-not-allowed opacity-50" : ""
            }`}
            onClick={() => {
              if (isDisabled) return;

              item?.stageName === "Offer"
                ? showModalOffer(item?.rpmStageName)
                : item?.stageName === "Interview Results"
                ? showModalScore(item?.rpmStageName)
                : item?.stageName === "Schedule Interview"
                ? navigate("/activity-list")
                : showModalConfirmation(item?.rpmStageName);
            }}
          >
            {item?.stageName}
          </div>
        ),
      };
    }) || []),
    {
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[19px]"
          onClick={() => {
            showModalConfirmation("DROP");
          }}
        >
          Drop
        </div>
      ),
    },
  ];

  // const settingItems = [
  //   ...(recruitmentProcess?.map((item) => ({
  //     label: (
  //       <div
  //         className="font-dmSans text-black font-[400] text-[19px]"
  //         onClick={() => {
  //           item?.stageName === "Offer"
  //             ? showModalOffer(item?.rpmStageName)
  //             : item?.stageName === "Interview Results"
  //             ? showModalScore(item?.rpmStageName)
  //             : item?.stageName === "Schedule Interview"
  //             ? navigate("/activity-list")
  //             : showModalConfirmation(item?.rpmStageName);
  //         }}
  //       >
  //         {item?.stageName}
  //       </div>
  //     ),
  //   })) || []),
  //   {
  //     label: (
  //       <div
  //         className="font-dmSans text-black font-[400] text-[19px]"
  //         onClick={() => {
  //           showModalConfirmation("DROP");
  //         }}
  //       >
  //         Drop
  //       </div>
  //     ),
  //   },
  // ];

  useEffect(() => {
    setReceivedId(jobEditId ? jobEditId : singleCreateJob?.jobId);
  }, [jobEditId, singleCreateJob?.jobId]);

  async function fetchCandidateJobApplications() {
    const res = await dispatch(
      fetchJobApplicationsApplicant({
        // japJobId: receivedId ?? "" ,
        japJobId: receivedId ? receivedId : activityJobId ? activityJobId : "",
        japInstId: user?.usrInstId,
        japProgressStatus: formData,
      })
    );
  }

  async function fetchCount() {
    const res = await dispatch(
      fetchJobStatsCount({
        jobId: receivedId,
      })
    );
  }

  // const onSelectChange = async (keys, rows) => {
  //   setRowSelected(keys);
  //   await dispatch(
  //     setSelectedRowId(
  //       intervalTime === "Block" || intervalTime === "Auto"
  //         ? keys
  //         : keys[keys?.length - 1]
  //     )
  //   );
  //   await dispatch(
  //     setSelectedRow(
  //       intervalTime === "Block" || intervalTime === "Auto"
  //         ? rows
  //         : rows[rows?.length - 1]
  //     )
  //   );
  // };
  const handleEmployeeToReturns = async (selectedRows) => {
    setRowIdBulk(selectedRows);
    setApplicantEdit(selectedRows);
  };
  const onSelectChange = (keys, rows) => {
    setSelectedRowKeysBulk(keys);
    handleEmployeeToReturns(rows);
  };
  const rowSelectionBulk = {
    selectedRowKeysBulk,
    onChange: onSelectChange,
  };

  const rowSelection = {
    onChange: onSelectChange,
    selectedRowKeys: rowSelected,
    getCheckboxProps: (record) => ({
      disabled: record.japProgressStatus === "INTERVIEW",
    }),
    type:
      intervalTime === "Block" || intervalTime === "Auto"
        ? "checkbox"
        : "radio",
  };

  async function fetchRecruitmentProcessMenusDetails() {
    await dispatch(
      fetchRecruitmentProcessMenus({
        rpmJobId: jobEditId
          ? jobEditId
          : activityJobId
          ? activityJobId
          : singleCreateJob?.jobId,
      })
    );
  }

  useEffect(() => {
    fetchRecruitmentProcessMenusDetails();
  }, []);

  useEffect(() => {
    if (refetchKey == 2 || formData || refresh) {
      fetchCandidateJobApplications();
    }
  }, [refetch, refetchKey, formData, refresh]);

  useEffect(() => {
    setApplicantJobApplicationList(jobApplicationPerJob);
  }, [jobApplicationPerJob]);

  useEffect(() => {}, [applicantJobApplicationList]);

  async function cleanCandidateDetails() {
    await dispatch(cleanCandidate());
  }
  useEffect(() => {
    cleanCandidateDetails();
  }, []);

  return (
    <>
      <div className="mt-[13px] mb-[34px] flex items-center justify-between">
        <Select
          showSearch
          style={{
            width: 250,
            height: "45px",
          }}
          name="selector"
          placeholder="Search to Select"
          optionFilterProp="children"
          onSearch={onSearch}
          filterOption={filterOption}
          options={[
            { value: "All", label: "All Applicants" },
            ...(recruitmentProcess && recruitmentProcess.length > 0
              ? recruitmentProcess.map((item) => ({
                  value: item?.rpmStageName,
                  label: item?.stageName
                    ? transformToPastTense(item?.stageName)
                    : null,
                }))
              : []),
            { value: "Drop", label: "Dropped" },
          ]}
          onChange={(value, label) => {
            if (value == "SCHEDULE INTERVIEW") {
              setFormData("INTERVIEW");
            } else {
              setFormData(value);
            }
          }}
          value={formData}
          defaultValue={formData}
          onDropdownVisibleChange={() => {
            fetchRecruitmentProcessMenusDetails();
          }}
          className="candidate-select candidate-arrow candidate-select-placeholder candidate-select-placeholder"
        />
        {rowIdBulk?.length > 1 && (
          <div className="flex justify-end">
            <Dropdown
              overlayStyle={{
                width: "350px",
                maxHeight: "400px",
                overflowY: "auto",
              }}
              className="border border-blueDark py-[1rem] px-[1.75rem] w-[15.0625rem] h-[3.5rem] rounded-[2.125rem]"
              trigger={"click"}
              menu={{ items: settingItems }}
              placement="bottom"
            >
              <button
                // onClick={() => setApplicantEdit(item)}
                className="cursor-pointer text-blueDark text-[1.1875rem] font-dmSans font-[500] flex items-center justify-center"
              >
                <span className="flex items-center gap-x-2">
                  <span className="text-[.75rem] h-[1.5rem] w-[1.5rem] flex items-center justify-center rounded-full bg-blueDark text-white">
                    {rowIdBulk?.length}
                  </span>
                  Bulk actions
                  <span>
                    <img src={carretDown} alt="carretDown" />
                  </span>
                </span>
              </button>
            </Dropdown>
          </div>
        )}
      </div>

      <div>
        <Table
          rowSelection={rowSelectionBulk}
          size={"middle"}
          bordered={true}
          pagination={{
            position: ["bottomCenter"],
          }}
          rowKey={(record) => record?.japId}
          // columns={tableColumns}
          columns={tableColumns?.filter((column) => !column.hidden)}
          dataSource={applicantJobApplicationList}
          scroll={{
            x: 800,
          }}
          loading={jobApplicationLoading}
        />
      </div>

      <ConfirmationModal
        isModalOpen={isModalOpenConfirmation}
        setIsModalOpen={setIsModalOpenConfirmation}
        handleSubmit={handleSubmit}
        loading={jobApplicationLoading}
        content={rowIdBulk?.length > 1 ? contentBulk : content}
      />
      <InterviewScoreModal
        isModalOpen={isModalOpenScore}
        setIsModalOpen={setIsModalOpenScore}
        applicantEdit={applicantEdit}
        prodd={prodd}
        fetchFunction={fetchCandidateJobApplications}
        fetchCount={fetchCount}
      />

      <OfferModal
        isModalOpen={isModalOpenOffer}
        setIsModalOpen={setIsModalOpenOffer}
        applicantEdit={applicantEdit}
        prodd={prodd}
        fetchFunction={fetchCandidateJobApplications}
        fetchCount={fetchCount}
      />

      <ApplicantDrawer open={open} setOpen={setOpen} cvType={cvType} />
    </>
  );
}

export default Candidates;
